import React from 'react';
import { graphql } from 'gatsby';
import { ListProducts } from '../shared/html/list-products-template';
import { useFlexSearch } from 'react-use-flexsearch';

function CategorizedProducts({ data, pageContext }) {
  const { pageSize, current, category } = pageContext;

  const index = data.localSearchByCategory.index;
  const store = data.localSearchByCategory.store;

  const categorizedPaginatedProducts = useFlexSearch(category, index, store, {
    limit: pageSize,
    offset: (current - 1) * pageSize,
  });

  const seoConfig = {
    title: 'Literature',
  };

  return ListProducts(
    categorizedPaginatedProducts,
    pageContext,
    category,
    seoConfig
  );
}

export default CategorizedProducts;

export const query = graphql`
  query {
    localSearchByCategory {
      index
      store
    }
  }
`;
